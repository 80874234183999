import React from "react";

export default function MainPage(props) {
  return (
    <div>
      <div className="container">
        <div className="row mb-3">
          Hello world!
        </div>
      </div>
    </div>
  );
}
